<template>
  <!--
    The view for the VideoPublisher-component.
  -->
  <div class="videoPublisherView">
    <VideoPublisher
      ref="videoPublisher"
      :installation-id="installationId"
      :lane-number="laneNumber"
    />
  </div>
</template>

<script>
export default {
  name: 'VideoPublisherView',
  components: {
    VideoPublisher: () => import('@/components/VideoPublisher/VideoPublisher.vue')
  },
  props: {
    installationId: {
      type: String,
      required: true
    },
    laneNumber: {
      type: String,
      required: false,
      default () {
        return null;
      }
    }
  },
  metaInfo () {
    return {
      title: this.$t('videoPublisherComp.videoPublisher')
    }
  }
}
</script>
